import React, { useState, useEffect } from "react";
import firestore from '../../../utils/firestore';
import ImageGallery from 'react-image-gallery';
import { Link } from "gatsby";
import { css } from 'emotion';

import Layout from '../../../components/Layout';
import Title from '../../../components/Title';

const main = css({

  '.card': {
    width: '50%',
    margin: '0 auto',

    '.card-footer-item': {
      background: '#C7EBBC'
    }
  }

});

const Gallery = () => {
  let [storageRef, setStorageRef] = useState(firestore.storageRef);
  let [images, setImages] = useState([]);
  let [currentIndex, setCurrentIdex] = useState(0);

  useEffect(() => {

    getImages();

  }, []);

  const getImages = async () => {

    const listRef = storageRef.child('Gallery');

    const res = await listRef.listAll();
    const items = res.items.map(item => item);
    const imgItems = [];

    for (let item of items) {
      const url = await item.getDownloadURL();
      const metadata = await item.getMetadata();
      item = Object.assign(item, metadata.customMetadata);
      console.log({ item })

      imgItems.push({
        name: item.name,
        original: url,
        thumbnail: url,
        order: item.order,
        title: item.title,
        author: item.author,
        price: item.price,
        instagram: item.instagram,
        description: `${item.author}: ${item.title}`
      });
    }

    setImages(imgItems.sort((a, b) => a.order >= b.order ? 1 : -1));
  }

  const onBeforeSlide = (nextIndex) => setCurrentIdex(nextIndex);

  return (
    <Layout>
      <section className={main}>
        <div className="content">
          <div className="columns">
            <div className="column is-10 is-offset-1">

              <Title title="Galería" />
              <br />

              {
                images.length > 0 ?
                  <>
                    <ImageGallery
                      items={images}
                      lazyLoad={true}
                      showIndex={true}
                      onBeforeSlide={onBeforeSlide}
                    />
                    <hr />
                    
                    <div className="card">
                      <div className="card-content">
                        <p className="title">
                          {images[currentIndex]["author"]}
                        </p>

                        <p className="subtitle">
                          Título: {images[currentIndex]["title"]}
                        </p>

                        <p className="subtitle">
                          Precio: {images[currentIndex]["price"]}
                        </p>
                      </div>
                      <footer className="card-footer">
                        <p className="card-footer-item">
                          <span>
                            Instagram <a href={`https://instagram.com/${images[currentIndex]["instagram"]}`} target="_blank" rel="noopener noreferrer" title="Facebook">@{images[currentIndex]["instagram"]}</a>
                          </span>
                        </p>
                      </footer>
                    </div>
                  </>
                  :
                  <div className="box">
                    <h3 className="is-subtitle is-size-3">¡Próximamente!</h3>
                    <p>Pronto actualizaremos nuestra Exposición Fotográfica, regresa luego</p>
                  </div>
              }

              <br />
              <div className="has-text-centered">
                <Link className="button has-text-primary" to="/galeria" rel="prev">
                  {'< Regresar'}
                </Link>
                <br /><br />
              </div>
            </div>

          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Gallery;